<script setup>

import { ref } from "vue"
import { useTagStore } from "@/store/tag"
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast"

const confirm = useConfirm()
const tagStore = useTagStore()
const toast = useToast()

const tags = ref([])
const loading = ref(true)
const filter = ref(null)
const totalRecords = ref(0)

const createOrUpdate = ref({
	createMode: true,
	dialog: false,
	loading: false,
	model: {
		id: null,
		note_private: null,
		tag_name: null,
	},
})

const remove = ref({
	loading: false,
})

const clearFilter = () => {
	filter.value = null
	search()
}

const confirmRemove = (item) => {
	confirm.require({
		message: 'Are you sure you want to remove?',
		header: 'Confirmation',
		accept: () => {
			remove.value.loading = true

			tagStore.destroy(item.id).then(() => {
				search()

				toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})
			}).catch((e) => {
				toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
			}).finally(() => {
				remove.value.loading = false
			})
		}
	});
}

const createTag = () => {
	createOrUpdate.value.createMode = true
	createOrUpdate.value.dialog = true

	createOrUpdate.value.model.id = null
	createOrUpdate.value.model.tag_name = null
	createOrUpdate.value.model.note_private = null
}

const creatingOrUpdating = () => {
	createOrUpdate.value.loading = true

	const requesting = createOrUpdate.value.createMode
		? tagStore.store({ name: createOrUpdate.value.model.tag_name, note_private: createOrUpdate.value.model.note_private })
		: tagStore.update(createOrUpdate.value.model.id, { note_private: createOrUpdate.value.model.note_private })

	requesting.then(({ data }) => {
		if (data.error) {
			toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
			return
		}

		createOrUpdate.value.dialog = false
		createOrUpdate.value.model.tag_name = null
		createOrUpdate.value.model.note_private = null

		search()

		toast.add({severity:'success', summary: 'Success', detail: data.message, life: 3000})
	}).catch((e) => {
		toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
	}).finally(() => {
		createOrUpdate.value.loading = false
	})
}

const search = (e = {}) => {
	loading.value = true

	const page = (e.page || 0) + 1
	tagStore.index({ filter: filter.value, page }).then((res) => {
		tags.value = res.data.tags.data
		totalRecords.value = res.data.tags.total

		loading.value = false
	})
}

const updateTag = (item) => {
	createOrUpdate.value.createMode = false
	createOrUpdate.value.dialog = true

	createOrUpdate.value.model.id = item.id
	createOrUpdate.value.model.tag_name = item.name
	createOrUpdate.value.model.note_private = item.note_private
}

search()

</script>

<template>
	<Dialog
		v-model:visible="createOrUpdate.dialog"
		:header="createOrUpdate.createMode ? 'New Tag' : 'Update Tag'"
		modal
		:style="{ width: '25vw' }"
	>
		<label class="block text-900 font-medium mb-2" for="tag_name">Name</label>
		<InputText v-model="createOrUpdate.model.tag_name" class="w-full mb-3" :disabled="! createOrUpdate.createMode" id="tag_name" type="text" />
		<Textarea v-model="createOrUpdate.model.note_private" class="w-full mb-3" id="note_private" rows="5" />

		<br/>

		<Button :label="createOrUpdate.createMode ? 'Save' : 'Update'" class="w-full" :loading="createOrUpdate.loading" @click="creatingOrUpdating" />
	</Dialog>

	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>
					Tags
					<Button label="Create" icon="pi pi-plus" class="p-button-sm ml-3" @click="createTag" />
				</h5>
				<DataTable
					class="p-datatable-gridlines"
					dataKey="id"
					filterDisplay="row"
					:lazy="true"
					:loading="loading"
					:paginator="true"
					responsiveLayout="scroll"
					:rowHover="true"
					:rows="10"
					:totalRecords="totalRecords"
					:value="tags"
					@page="search($event)"
				>

					<template #header>
						<div class="flex justify-content-between flex-column sm:flex-row">
							<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
							<div class="col-12 md:col-4">
								<div class="p-inputgroup">
									<InputText v-model="filter" placeholder="Name" />
									<Button icon="pi pi-search" class="p-button-warning" @click="search" />
								</div>
							</div>
						</div>
					</template>
					<template #empty>
						No records.
					</template>
					<template #loading>
						Loading. Please wait.
					</template>

					<Column field="name" header="Name">
						<template #body="{data}">
							<Chip :label="String(data.name)" />
						</template>
					</Column>

					<Column field="counts" header="Counts" />

					<Column field="note_private" header="Note (Private)" />

					<Column header="Options">
						<template #body="{data}">
							<router-link :to="`/members?tag=${data.id}`">
								<Button icon="pi pi-users" class="p-button-rounded p-button-primary mr-2 mb-2" />
							</router-link>

              <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" @click="updateTag(data)" />

              <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2 mb-2" :disabled="data.counts" @click="confirmRemove(data)" />
						</template>
					</Column>

				</DataTable>
			</div>
		</div>
	</div>
</template>
